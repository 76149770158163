<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <div class="row">
            <div class="col-md-4">
              <b-form-group id="input-group-photo">
                <label class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
                  >Gambar</label
                >
                <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                  <div
                    class="image-input image-input-outline"
                    id="kt_profile_avatar"
                  >
                    <div class="image-input-wrapper">
                      <img :src="photo" alt="" />
                    </div>
                    <label
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="current_photo = null"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="col-md">
              <b-form-group id="input-group-inventory-code">
                <label for="input-inventory-code"
                  >Kode Inventory: </label
                >
                <b-form-input
                  id="input-inventory-code"
                  v-model="form.inventory_code"
                  placeholder="Kode Inventory"
                ></b-form-input>
                <small class="text-danger">{{ error.inventory_code }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-name"
                label="Nama:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Nama"
                ></b-form-input>
                <small class="text-danger">{{ error.name }}</small>
              </b-form-group>

              <div class="row">
                <div class="col">
                  <b-form-group id="input-group-serial-number">
                    <label for="input-serial-number"
                      >No. Serial: <em class="text-muted">opsional</em></label
                    >
                    <b-form-input
                      id="input-serial-number"
                      v-model="form.serial_number"
                      placeholder="No. Seri"
                    ></b-form-input>
                    <small class="text-danger">{{ error.serial_number }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group id="input-group-product-number">
                    <label for="input-product-number"
                      >No. Produk: <em class="text-muted">opsional</em></label
                    >
                    <b-form-input
                      id="input-product-number"
                      v-model="form.product_number"
                      placeholder="No. Produk"
                    ></b-form-input>
                    <small class="text-danger">{{
                      error.product_number
                    }}</small>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="input-group-specification">
                <label for="input-specification">Spesifikasi:</label>
                <b-form-textarea
                  id="input-specification"
                  v-model="form.specification"
                  placeholder="Spesifikasi"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.specification }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-tool-category"
                label="Kategori Alat:"
                label-for="input-tool-category"
              >
                <treeselect
                  v-model="form.tool_category_id"
                  :multiple="false"
                  placeholder="Pilih Kategori Alat"
                  :options="tool_categories"
                />
                <small class="text-danger">{{ error.tool_category_id }}</small>
              </b-form-group>

              <b-form-group id="input-group-price">
                <label for="input-price"
                  >Harga: <em class="text-muted">opsional</em></label
                >
                <b-form-input
                  id="input-price"
                  v-model="formatedPrice"
                  placeholder="Harga"
                ></b-form-input>
                <small class="text-danger">{{ error.price }}</small>
              </b-form-group>

              <b-form-group id="input-group-sop_usage">
                <label for="input-sop_usage">SOP Penggunaan:</label>
                <b-form-textarea
                  id="input-sop_usage"
                  v-model="form.sop_usage"
                  placeholder="SOP Penggunaan"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.sop_usage }}</small>
              </b-form-group>

              <div class="row">
                <div class="col">
                  <b-form-group id="input-group-brand">
                    <label for="input-brand"
                      >Merek: <em class="text-muted">opsional</em></label
                    >
                    <b-form-input
                      id="input-brand"
                      v-model="form.brand"
                      placeholder="Merek"
                    ></b-form-input>
                    <small class="text-danger">{{ error.brand }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group id="input-group-location">
                    <label for="input-location"
                      >Lokasi Penyimpanan:
                      <em class="text-muted">opsional</em></label
                    >
                    <b-form-input
                      id="input-location"
                      v-model="form.location"
                      placeholder="Lokasi Penyimpanan"
                    ></b-form-input>
                    <small class="text-danger">{{ error.location }}</small>
                  </b-form-group>
                </div>
              </div>
              
              <div class="row">
                <div class="col">
                  <b-form-group id="input-group-buy-date">
                    <label for="input-buy-date"
                      >Tanggal Beli: <em class="text-muted">opsional</em></label
                    >
                    <v-menu
                      v-model="menuDate1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <b-form-input
                          id="input-date"
                          v-model="form.buy_date"
                          label="Tanggal Beli:"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></b-form-input>
                      </template>
                      <v-date-picker
                        v-model="form.buy_date"
                        @input="menuDate1 = false"
                      ></v-date-picker>
                    </v-menu>
                    <small class="text-danger">{{ error.buy_date }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group id="input-group-inventory-date">
                    <label for="input-inventory-date"
                      >Tanggal Tercatat Inventaris:
                      <em class="text-muted">opsional</em></label
                    >
                    <v-menu
                      v-model="menuDate2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <b-form-input
                          id="input-date"
                          v-model="form.inventory_date"
                          label="Tanggal Tercatat Inventaris:"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></b-form-input>
                      </template>
                      <v-date-picker
                        v-model="form.inventory_date"
                        @input="menuDate2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <small class="text-danger">{{
                      error.inventory_date
                    }}</small>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/tools/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        name: "",
        serial_number: "",
        product_number: "",
        specification: "",
        tool_category_id: "",
        price: "",
        photo_url: "",
        tool_status_id: "",
        sop_usage: "",
        brand: "",
        buy_date: "",
        inventory_date: "",
        inventory_code: "",
        location: "",
      },
      current_photo: "/img/default/tool-default.svg",
      default_photo: "/img/default/tool-default.svg",
      tool_categories: [],
      menuDate1: false,
      menuDate2: false,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo_url = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo_url = "";
    },
    async getToolCategoryOption() {
      let response = await module.setTreeSelect("api/tool-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_categories = response.data;
        this.tool_categories.unshift({
          label: "Pilih Kategori Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async formOnsubmit() {
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/tools");
      }
    },
  },
  mounted() {
    this.getToolCategoryOption();
  },
  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;

        this.default_photo = this.form.photo_url;
        this.current_photo = this.form.photo_url;
      }
      if(this.purpose == "duplicate"){
        this.default_photo = this.form.photo_url;
        this.current_photo = this.form.photo_url;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>